"use client";

import { useSignUp } from "@clerk/nextjs";
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import isEmail from "validator/lib/isEmail";
import { BoxWrapper, InputFieldWrapper } from "../../styles/styledComponents";
import { HandleErrorWithToast, SuccessToast } from "../Toasts";

interface Props {
  signedIn: boolean;
}

const postRegisterUser = async ({ password, emailAddress, signUp, setActive }) => {
  try {
    const results = await signUp.create({ emailAddress, password });

    if (results.status === "complete") {
      await setActive({ session: results.createdSessionId });
    }

    window.scrollTo(0, 0);
    SuccessToast("Bruker registrert!");
  } catch (err: unknown) {
    HandleErrorWithToast(err);
  }
};

const LandingRegister = ({ signedIn }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { signUp, setActive } = useSignUp();
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [emailInFocus, setEmailInFocus] = useState(false);
  const [passwordExist, setPasswordExist] = useState(false);
  const [checked, setChecked] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!isEmail(userEmail) || !passwordExist) {
      enqueueSnackbar("Vennligst fyll inn feltene for å fortsette");
      return;
    }

    return postRegisterUser({ emailAddress: userEmail, password: userPassword, signUp, setActive });
  };

  // const PROTOTYPE_URL = "https://www.figma.com/proto/H27n1NgHHeLdPadOqO2CaE/HD---Web-site?page-id=15%3A3&type=design&node-id=27-927&viewport=4570%2C2728%2C1.11&scaling=min-zoom&starting-point-node-id=27%3A938";

  return (
    <>
      {!signedIn ? (
        <BoxWrapper id="register">
          <Container maxWidth="lg" sx={{ display: "flex", gap: "40px", alignItems: "center" }}>
            <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="h1" mb="30px">
                Bli med i Norges digitale hunde&shy;samfunn
              </Typography>
              <RegisterImageMobile src="/images/signUp.png/" />

              <form>
                <FormControl fullWidth sx={{ alignItems: "center" }}>
                  <InputFieldWrapper
                    label="E-postadresse"
                    key="email"
                    onChange={(e) => {
                      if (isEmail(e.target.value)) {
                        setIsValid(true);
                        setUserEmail(e.target.value);
                      }
                    }}
                    onFocus={() => {
                      setEmailInFocus(true);
                    }}
                    onBlur={() => {
                      setEmailInFocus(false);
                    }}
                    variant="standard"
                    type={"email"}
                    required
                    error={!isValid && emailInFocus}
                    helperText={emailInFocus && !isValid ? "Ikke gyldig e-postadresse" : ""}
                  />
                </FormControl>
                <br />
                <FormControl fullWidth sx={{ alignItems: "center" }}>
                  <InputFieldWrapper
                    label="Passord"
                    required
                    key="password"
                    onChange={(e) => {
                      if (e.target.value) {
                        setPasswordExist(true);
                        setUserPassword(e.target.value);
                      }
                    }}
                    variant="standard"
                    autoComplete={"new-password"}
                    type="password"
                  />
                </FormControl>
                <FormControlLabel
                  control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} color="primary" />}
                  label={
                    <span>
                      Jeg samtykker til HappyDogs sine{" "}
                      <Link target={"_blank"} href="../information/terms-of-use" color="inherit">
                        betingelser og brukervilkår.
                      </Link>{" "}
                      Les mer om hvordan vi samler inn, bruker og deler dataene dine, i våre{" "}
                      <Link target={"_blank"} href="../information/privacy" color="inherit">
                        retningslinjer for personvern.
                      </Link>{" "}
                      Du vil også motta regelmessig nyhetsbrev, men dette kan du skru av på "min side".
                    </span>
                  }
                />
              </form>
              <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={onSubmit}
                  disabled={!checked}
                  sx={{ marginTop: "20px", justifySelf: "center" }}
                >
                  Registrer deg
                </Button>
              </Box>
            </Box>
            <RegisterImage src="/images/signUp.png/" height={"536px"} />
          </Container>
        </BoxWrapper>
      ) : (
        <BoxWrapper id="register">
          <div>
            <Typography variant="h2" align="center">
              Du er registrert!
            </Typography>
            <RegisterImage src="/images/success.png/" height={"536px"} />
          </div>
        </BoxWrapper>
      )}
    </>
  );
};

export default LandingRegister;

const RegisterImage = styled("img")`
  display: block;
  height: 536px;
  @media screen and (max-width: 1200px) {
    height: 336px;
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
`;
const RegisterImageMobile = styled("img")`
  display: flex;
  height: 120px;
  align-self: center;
  justify-self: center;
  @media screen and (min-width: 900px) {
    display: none;
  }
`;
